import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  // TextField,
  Typography,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  //use scroll to element
  let navigate = useNavigate();

  const navigateAbout = () => {
    const element = document.getElementById("about");
    navigate("/");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navigateServices = () => {
    const element = document.getElementById("services");
    navigate("/");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F2EADD",
        color: "#000",
        fontFamily: "Montserrat",
      }}
    >
      <Container>
        <Box sx={{ pt: "%" }}>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              // border: "0.3px solid #b5a4a2",
              p: "2% 1px",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "12px", sm: "18px" },
                fontWeight: 500,
                fontFamily: "Montserrat",
              }}
            >
              Offering solutions to your needs
            </Typography>
            <Link sx={{ textDecoration: "none" }} href="/contact">
              <Button
                sx={{
                  fontWeight: 500,
                  fontFamily: "Montserrat",
                  fontSize: { xs: "13px", sm: "15px", md: "17px", lg: "20px" },
                  backgroundColor: "#9AA691",
                  color: "#fff",
                  p: { xs: "1px 15px", sm: "5px 30px" },
                  mt: "1%",
                  textTransform: "unset",
                  pb: "30px",
                }}
              >
                Contact us now
              </Button>
            </Link>
          </Box> */}
          <Box sx={{ pt: "5%" }}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={4}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "24px", sm: "30px" },
                      fontWeight: 600,
                      fontFamily: "Montserrat",
                    }}
                  >
                    PHRS
                  </Typography>
                  <Typography
                    sx={{
                      pt: "1%",
                      fontSize: { xs: "13px", sm: "17px" },
                      width: { xs: "95%", sm: "80%" },
                      fontWeight: 500,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Unlocking Human Potential and Driving Organizational Excellence!
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography sx={{ fontSize: "20px", fontWeight: 700 }}>
                  Links
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    lineHeight: { xs: "24px", sm: "30px" },
                  }}
                >
                  <Link
                    href="/"
                    style={{
                      textDecoration: "none", 
                      color: "#000",
                      fontWeight: 500,
                     
                    }}
                  >
                    Home
                  </Link>
                  <Link
                    onClick={() => navigateAbout()}
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      fontWeight: 500,
                    }}
                  >
                    About Us
                  </Link>
                  <Link
                    onClick={() => navigateServices()}
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      fontWeight: 500,
                    }}
                  >
                    Our Services
                  </Link>
                  {/* <Link
                    href="/pool"
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      fontWeight: 500,
                    }}
                  >
                    Talent Pools
                  </Link> */}
                  <Link
                    href="/contact"
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      fontWeight: 500,
                    }}
                  >
                    Contact Us
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 700,
                    fontFamily: "Montserrat",
                  }}
                >
                  Contact
                </Typography>
                <Box sx={{ lineHeight: "30px" }}>
                  <Typography
                    sx={{
                      lineHeight: "30px",
                      fontWeight: 500,
                      fontFamily: "Montserrat",
                    }}
                  >
                    +254743218671
                  </Typography>
                  <Typography
                    sx={{
                      lineHeight: "30px",
                      fontWeight: 500,
                      fontFamily: "Montserrat",
                    }}
                  >
                    services@pivotworkshr.org
                  </Typography>
                  <Typography
                    sx={{
                      lineHeight: "30px",
                      fontWeight: 500,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Westlands, Nairobi
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              pt: "3%",
            }}
          >
            <a
              href="https://www.linkedin.com/company/pivotworkshr/"
              target="_blank"
            >
              <LinkedInIcon
                sx={{
                  color: "#032d2e",
                  p: { xs: "", sm: "" },
                  fontSize: { xs: "24px", sm: "35px" },
                }}
              />
            </a>
            <a
              href="https://twitter.com/pivotworkshr?s=21&t=744hFtUA20Cl0Ho8d2-aag"
              target="_blank"
            >
              <TwitterIcon
                sx={{
                  color: "#032d2e",
                  m: { xs: "1px 40px", sm: "1px 70px" },
                  fontSize: { xs: "24px", sm: "35px" },
                }}
              />
            </a>
            <a
              href="https://instagram.com/pivotworkshr?igshid=MzRlODBiNWFlZA=="
              target="_blank"
            >
              <InstagramIcon
                sx={{
                  color: "#032d2e",
                  p: { xs: "", sm: "" },
                  fontSize: { xs: "24px", sm: "35px" },
                }}
              />
            </a>

            {/* <WhatsAppIcon
              onClick={() =>
                window.open(
                  `https://wa.me/+254717294815?text=Hello! I'm interested in your consultation, could I please get more info?.`
                )
              }
              sx={{
                p: { xs: "", sm: "" },
                fontSize: { xs: "24px", sm: "35px" },
              }}
            /> */}
          </Box>
        </Box>
      </Container>
      <Box
        sx={{
          textAlign: "center",
          mt: "1%",
          p: "1% 1px",
          color: "#fff",
          backgroundColor: "#DED6C9",
        }}
      >
        <Typography sx={{ fontSize: { xs: "8px", sm: "12px" } }}>
          ©2023 All Rights Reserved <b>--</b>
          {/* <a href="https://github.com/ro61zzy" style={{ color: "#fff" }}>
            {" "}
            Rose Wachuka
          </a>{" "} */}
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
